// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels")
require('components/header')
require('components/form')
require('components/popup')
require('components/menu-mobile')
require('components/map')
require('components/faq')
require('components/carousel')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import fadeInScroll from "../components/fade-in-scroll.js"

gsap.registerPlugin(ScrollToPlugin);

/// PAGE FADEIN ANIMATION
const FADEINTIME = 2;
document.addEventListener('DOMContentLoaded', ()=> {
	gsap.to(['body','html'], FADEINTIME, {opacity: 1, ease: Power4.easeInOut} )
})

/// scroll FADEIN ANIMATION
document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.pages-home')){
		fadeInScroll();
	}
})



//SCROLL TO
const scrollFunction = {
	init: () => {
		let scrollLinks = document.querySelectorAll('.js-scroll-to')
		scrollLinks.forEach(i => i.addEventListener('click', (e) => {
				let id = e.currentTarget.getAttribute('data-scroll')
				gsap.to(window, {duration: 1.5, scrollTo: `#${id}`, ease: "power4"})
			})
		)
	}
}



document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.js-scroll-to')){
		scrollFunction.init()
	}
})



