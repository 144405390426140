
 popup = {
	init: () => {
		let closePopup = document.querySelectorAll('.js-close-popup')
		closePopup.forEach(i => {
			i.addEventListener('click', () => {
				console.log('click')
				document.querySelectorAll('.js-popup').forEach(ii => {
					ii.classList.remove('is-active')
				})
				document.querySelector('html, body').classList.remove('noscroll')
			})
		})
		let openPopup = document.querySelectorAll('.js-open-popup')
		openPopup.forEach(i => {
			i.addEventListener('click', (e) => {
				let id = e.currentTarget.getAttribute('data-id')
				document.querySelector(`#${id}`).classList.add('is-active')
				document.querySelector('html, body').classList.add('noscroll')
			})
		})
	}
}



// FUNCTION TO CLOSE POPUP
document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.js-popup')){
		popup.init()

	}

})