const initHeader = () => {
	let openSub = document.querySelectorAll('.js-open-sub-header')
	let subHeaders = document.querySelectorAll('.header_sub')
	if (subHeaders.length > 0){
	openSub.forEach(item => {
		item.addEventListener('mouseover', () => {
			let id = item.getAttribute('data-sub')
			subHeaders.forEach(i => i.classList.remove('is-active'))
			document.querySelector(`#${id}`).classList.add('is-active')
		})
		item.addEventListener('mouseleave', () => {
			subHeaders.forEach(i => i.classList.remove('is-active'))
		})
	})
	}

	// queste funzioni servono per tenere aperti i sottomenu quando ho l'over col mouse 
	// su di esse. I listener qua sopra forniscono un'azione quando si passa il mouse su
	// header (non subheader)
	subHeaders.forEach(item => {
		item.addEventListener('mouseover', () => {
			subHeaders.forEach(i => i.classList.remove('is-active'))
			item.classList.add('is-active')
		})

		item.addEventListener('mouseleave', () => {
			subHeaders.forEach(i => i.classList.remove('is-active'))
		})
	})
}



document.addEventListener('DOMContentLoaded', () => {
	initHeader()
})