document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('.faq')){
		document.querySelectorAll('.faq_cat').forEach(i => {
			i.addEventListener('click', () => {
				let open
				if (i.classList.contains('is-active')){
					open = true
				} else {
					open = false
				}
				document.querySelectorAll('.faq_cat').forEach(ii => {
					ii.classList.remove('is-active')
				})
				document.querySelectorAll('.faq_contents').forEach(ii => {
					ii.style.height = '0px'
				})
				if (open == false ){
					i.classList.add('is-active')
					let id = i.getAttribute('data-id')
					var ele = document.getElementById(`faq-cat-${id}`);
			        ele.style.height = ele.scrollHeight+"px";
				}
				
				i.scrollIntoView()
				
				
	  
			})
		})
	}
})
