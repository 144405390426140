import axios from 'axios';
// OPEN CLOSE FORM
const form = {
	init: () => {
		// for style of labels
		let inputs = document.querySelectorAll('form input, form textarea')
		inputs.forEach(i => {
			// if (i.value != ''){
			// 	i.closest('.input').classList.add('not-empty')
			// }
			i.addEventListener('keyup', () => {
				// doppio controllo se c'è già inserito un valore

				if(i.value != ''){
					i.classList.add('not-empty')
				} else {
					i.classList.remove('not-empty')
				}
			})
		})
		let select = document.querySelector('select')
		// if (select.value != ''){
		// 		select.closest('.input').classList.add('not-empty')
		// 	}
		select.addEventListener('change', () => {
			select.classList.add('not-empty')
		})
		let sendBtn = document.querySelectorAll('.js-send-message')
		sendBtn.forEach(i => {
			i.addEventListener('click', (e) => {
				e.preventDefault()
				e.currentTarget.classList.add('disabled')
				let formToSubmit = e.currentTarget.closest('form')
				// if (document.querySelector('#nickname').value == '' && document.querySelector('#family_name').value == ''){
					form.send_message(formToSubmit)
				// }
			})
		})
	},
	send_message: (formToSubmit) => {
		let data = new FormData(formToSubmit)
		axios({
		 	url: formToSubmit.action,
		  	method: formToSubmit.method,
		  	headers: {
		    	'Accept': 'application/json'
		  	},
		  	data: data
		}).then((response) => {
			if (response.status == 200){
				document.querySelector('.js-popup.is-active').classList.remove('is-active')
				document.querySelector('html, body').classList.remove('noscroll')
				document.querySelector('.mini-alert.success').classList.add('is-active')
			} else {
				document.querySelector('.mini-alert.error').classList.add('is-active')
			}
			document.addEventListener('click', () => {
				// close mini alert on click
				document.querySelector('.mini-alert.is-active').classList.remove('is-active')
			})
		 })
		
	}
}


document.addEventListener('DOMContentLoaded', () => {
	if(document.querySelector('#contact-form')){
		form.init()
		form.send_message
	}
})