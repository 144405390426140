import CSSPlugin from 'gsap/CSSPlugin';
import { gsap, Power4 }  from "gsap"; // Also works with TweenLite and TimelineLite
const myPlugins = [CSSPlugin]


const menuMobile = {
	toggle: () => {

		let hamburger = document.querySelector('.js-toggle-menu')
		let menu = document.querySelector('.mobile-menu')
		let isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
		if (isSafari) { 
		  menu.classList.add('is-safari')
		};

		hamburger.addEventListener('click', () => {
			document.querySelector('html, body').classList.toggle('noscroll')
			if(!menu.classList.contains('is-active')){
				gsap.fromTo( menu.querySelectorAll('.mobile-menu_item, .mobile-menu_submenu_item'), {autoAlpha:0, x:-10}, {duration: 0.3, autoAlpha:1, x:0, stagger:0.08, ease: Power4.out})
				// document.addEventListener('click', closeMenuIfOutside)
			} else {
				gsap.to( menu.querySelectorAll('.mobile-menu_item, .mobile-menu_submenu_item'), {autoAlpha:0, x:-10, duration: 0.3})
				// document.removeEventListener('click', closeMenuIfOutside)
			}
			menu.classList.toggle('is-active')
			hamburger.classList.toggle('is-active')
		})
	},
	toggleSubmenu: () => {
		let openSubmenu = document.querySelectorAll('.js-open-mobile-sub-menu')
		openSubmenu.forEach(i => {
			i.addEventListener('click',  (e) => {
				let id = e.currentTarget.getAttribute('data-sub')
				e.currentTarget.classList.toggle('submenu-active')
				document.querySelector(`#${id}`).classList.toggle('is-active')
			})
		})
	},
	anchor: () => {
		let anchors = document.querySelectorAll('.js-mobile-menu-anchor')
		let menu = document.querySelector('.mobile-menu')
		let hamburger = document.querySelector('.js-toggle-menu')
		anchors.forEach( i => {
			i.addEventListener('click', (e) => {
				menu.classList.toggle('is-active')
				hamburger.classList.toggle('is-active')
				document.querySelector('html, body').classList.toggle('noscroll')
			})
		})
	}
}

document.addEventListener('DOMContentLoaded', () => {
	menuMobile.toggle()
	menuMobile.toggleSubmenu()
	menuMobile.anchor()
})