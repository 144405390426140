export default function() {
	const inView = (el) => {
		const scroll = window.scrollY || window.pageYOffset
		const boundsTop = el.getBoundingClientRect().top + scroll

		const viewport = {
			top: scroll,
			bottom: scroll + window.innerHeight
		}
	
		const bounds = {
			top: boundsTop,
			bottom: boundsTop + el.clientHeight
		}
		const offset = 100
		//ritorna true or false, vede se elemento è nella viewport
		return ( bounds.bottom >= viewport.top + offset && bounds.bottom <= viewport.bottom - offset)
		|| ( bounds.top <= viewport.bottom - offset && bounds.top >= viewport.top + offset)
	}


	const elements = document.querySelectorAll('.js-fade-in')

	
	elements.forEach((el) => {
		if (inView(el)) {
			el.classList.add('done')
		}
	})

	window.onscroll = () => {
		elements.forEach((el) => {
			if (inView(el)) {
				el.classList.add('done')
			}
		})
	}

	window.onload = () => {
		elements.forEach((el) => {
			if (inView(el)) {
				el.classList.add('done')
			}
		})
	}
}