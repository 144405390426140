document.addEventListener('DOMContentLoaded', ()=>{
	let images = document.querySelectorAll('.hero_image img')
	let i = 0
	if (images.length > 1 ){
		setTimeout(function next() {
			images[i].classList.remove('is-active')
			if(i == 2){ i = -1 }
			images[i+1].classList.add('is-active')
			i ++
		    setTimeout(next, 4000);

		}, 10);
	}
	
})